
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  SingleCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    SingleCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 2;
    const router = useRouter();

    const title = "Choose the statement that best applies to you.";
    const options = [
      {
        label:
          "I know which brand/type of birth control I want, and I'd like to make a purchase.",
        value:
          "I know which brand/type of birth control I want, and I'd like to make a purchase.",
      },
      {
        label:
          "I'm unsure what's best for me / I want to switch brands, and I'd like to discuss contraceptive options with a doctor first.",
        value:
          "I'm unsure what's best for me / I want to switch brands, and I'd like to discuss contraceptive options with a doctor first.",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      questionNumber,
      title,
    };
  },
});
